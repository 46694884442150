.steps-item {
  width: 55px;
  height: 55px;
  display: flex;
  min-width: 55px;
  cursor: pointer;
  font-size: 30px;
  font-weight: 700;
  line-height: 35px;
  letter-spacing: 0;
  text-align: center;
  align-items: center;
  background: #FAFDF3;
  border-radius: 100px;
  align-content: center;
  justify-content: center;
  border: none !important;
  font-family: Roboto, "Roboto", Arial, sans-serif;
  transition: background-color 0.3s, border-color 0.3s;
  -webkit-transition: background-color 0.3s, border-color 0.3s;
}

.steps-label {
  font-family: 'Roboto', Roboto, Arial, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 35px;
  text-align: center;
  color: #312F2F;
  margin: auto;
}

.line-bar {
  height: 4px;
  margin-top: auto;
  position: relative;
  margin-bottom: auto;
  background-color: #FAFDF3;
}

.steps-item:hover {
  z-index: 2;
  -webkit-box-shadow: 0px 0px 4px 5px rgba(0, 0, 0, 0.27);
  -moz-box-shadow: 0px 0px 4px 5px rgba(0, 0, 0, 0.27);
  box-shadow: 0px 0px 4px 5px rgba(0, 0, 0, 0.27);
}

.steps-item.finished {
  background-color: var(--green-v2);
  box-shadow: none !important;
}

.steps-item.finished span {
  color: white;
}
