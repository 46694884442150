.breadcrumbs-text {
  color: white;
}

.breadcrumbs-text:hover {
  color: white;
}

.arrow-nav {
  margin-left: 10px;
  margin-right: 10px;
}

.div-breadcrumbs {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.div-breadcrumbs-mobile {
  vertical-align: sub !important;
}

.div-breadcrumbs-mobile .breadcrumbs-text {
  color: black !important;
}

.div-breadcrumbs-mobile .arrow-nav {
  width: 5px;
  height: 10px;
  margin-top: -2px;
  margin-left: 5px;
  margin-right: 5px;
  content: url("../../../../../assets/img/icons/blackarrow.svg") !important;
}
