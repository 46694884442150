.direct-purchase-form {
    max-width: 50%;
}

@media (max-width: 1630px) {
    .direct-purchase-form {
        max-width: 70%;
    }
}

@media (max-width: 1000px) {
    .direct-purchase-form {
        max-width: 80%;
    }
}

@media (max-width: 800px) {
    .direct-purchase-form {
        max-width: 100%;
    }
}

.search-product {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-width: 60%;
}

.search-product-btn {
    min-width: 20%;
}

@media (max-width: 1300px) {
    .search-product {
        max-width: 70%;
    }
}

@media (max-width: 1100px) {
    .search-product {
        max-width: 80%;
    }
}

@media (max-width: 1000px) {
    .search-product {
        max-width: 100%;
    }
}

@media (max-width: 800px) {
    .search-product {
        flex-direction: column;
    }
}