.title-grafica {
  font-family: "Roboto";
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  margin: 0;
}

.title-grafica-light {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  margin: 0;
}
