@media only screen and (min-width: 1000px) {
    .petty-cash-entry-form {
        max-width: 90% !important;
    }
}

@media only screen and (min-width: 1300px) {
    .petty-cash-entry-form {
        max-width: 70% !important;
    }
}


@media only screen and (min-width: 1525px) {
    .stamps-form {
        max-width: 70%;
    }
}