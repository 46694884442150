.title-grafica{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  margin: 0;
}

.title-grafica-light{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  margin: 0;
}

.perfil-usuario p{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  margin: 0;
  color: #4B4D58;
}

.tipo-colegiado{
  color: #B0A196 !important;
}

/* DESKTOP  */
.parent-dashboard {
  display: grid !important;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: repeat(4, minmax(1fr, 359px));
  gap: 15px;
  padding: 15px 15px;
  min-width: 1000px;
}

.parent-dashboard div:nth-child(1) {
  grid-area: 1 / 1 / 2 / 6;
}
.parent-dashboard div:nth-child(2) {
  grid-area: 1 / 6 / 2 / 13;
}
.parent-dashboard div:nth-child(3) {
  grid-area: 2 / 1 / 3 / 13;
}
.parent-dashboard div:nth-child(4) {
  grid-area: 3 / 1 / 4 / 13;
}
.parent-dashboard div:nth-child(5) {
  grid-area: 4 / 1 / 5 / 7;
}
.parent-dashboard div:nth-child(6) {
  grid-area: 4 / 7 / 5 / 13;
}

/* MOBILE  */
@media only screen and (max-width: 1280px) {
  .parent-dashboard {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(6, minmax(1fr, 289px));
    margin-left: 5%;
  }
  .parent-dashboard div:nth-child(1) {
    grid-area: 1 / 1 / 2 / 3;
  }
  .parent-dashboard div:nth-child(2) {
    grid-area: 2 / 1 / 3 / 3;
  }
  .parent-dashboard div:nth-child(3) {
    grid-area: 3 / 1 / 4 / 3;
  }
  .parent-dashboard div:nth-child(4) {
    grid-area: 4 / 1 / 5 / 3;
  }
  .parent-dashboard div:nth-child(5) {
    grid-area: 5 / 1 / 6 / 3;
  }
  .parent-dashboard div:nth-child(6) {
    grid-area: 6 / 1 / 7 / 3;
  }
}
