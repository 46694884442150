@media only screen and (min-width: 700px) and (max-width: 1023px) {
    .stamps-form {
        min-width: 100%;
    }
}

@media only screen and (min-width: 1024px) and (max-width: 1524px) {
    .stamps-form {
        max-width: 100%;
        overflow: auto;
    }
}

@media only screen and (min-width: 1525px) {
    .stamps-form {
        max-width: 95%;
    }
}

.stamps-form {
    background-color: #E2DDD9;
    border-radius: 20px;
    box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.25);
}

.icon-picker .uploader {
    min-width: 100% !important;
}

.btns-stamps {
    width: 200px;
    border-radius: 20px !important;
}

.save-button-stamps {
    background-color: #157339 !important;
    width: 200px !important;
}

.btn-add-document {
    background: #FAFDF3 !important;
    width: 200px;
    border: 2px solid #0A4725 !important;
    border-radius: 20px !important;
}

.icon-plus {
    background-position: -10px 10px !important;
    ;
}

.back-arrow {
    transform: scaleX(-1);
    filter: FlipH;
    -ms-filter: "FlipH";
    float: none;
}

@media screen and (max-width: 1700px) {
    .check-documents {
        flex-direction: column-reverse;
    }
}

@media screen and (max-width: 767px) {
    .cancelar-anterior {
        min-width: 100%;
        margin-bottom: 0.5%;
    }

    .guardar-siguiente {
        min-width: 100%;
    }
}

/* .modules-reports-container .c-checkbox input[type='checkbox'] + span{
    border: 2px solid #777FF3 !important;
    border-radius: 4px;
}

.modules-reports-container .c-checkbox input[type='checkbox']:checked + span{
    background-color: #777FF3 !important;
    box-shadow: inset 1px 1px #FAFDF3 , inset -1px -1px #FAFDF3 ;
} */

.title-format-sheet {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

@media screen and (max-width: 1230px) {
    .title-format-sheet {
        flex-direction: column;
    }
}

.background-signatures {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.signatures {
    padding: 10px;
}

@media screen and (max-width: 1500px) {
    .background-signatures {
        flex-direction: column;
        align-items: normal;
    }
}

.field-form .c-checkbox input[type='checkbox']+span {
    border: 2px solid #777FF3 !important;
    border-radius: 4px;
}

.field-form .c-checkbox input[type='checkbox']:checked+span {
    background-color: #777FF3 !important;
    box-shadow: inset 1px 1px #FAFDF3, inset -1px -1px #FAFDF3;
}