.filter-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;
}

.w-33 {
  width: 33%;
}
.vertical-bar-separator {
  position: relative;
  margin-right: 20px;
}
.vertical-bar-separator::after {
  content: "";
  display: block;
  width: 2.5px;
  height: 80%;
  background-color: #b0a196;
  position: absolute;
  top: calc(50% - 40%);
  right: -18px;
}

.horizontal-bar-separator {
  position: relative;
}

.horizontal-bar-separator::after {
  content: "";
  display: block;
  width: 95%;
  height: 2px;
  background-color: #b0a196;
  position: absolute;
  top: calc(-30%);
  left: 50%;
  transform: translate(-50%, -25%);
}

