/* Paso 1 */

.inputs-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    grid-gap: 1rem;
    margin-bottom: 1rem;
}

@media screen and (max-width: 1530px) {
    .inputs-container {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(450px, 1fr));
        grid-gap: 1rem;
        margin-bottom: 1rem;
    }
}

@media screen and (max-width: 550px) {
    .inputs-container {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
        grid-gap: 1rem;
        margin-bottom: 1rem;
    }
}

@media screen and (max-width: 400px) {
    .inputs-container {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(225px, 1fr));
        grid-gap: 1rem;
        margin-bottom: 1rem;
    }
}

/* Paso 2 */

.collegiate-spouse-container {
    max-width: 65%;
}

@media screen and (max-width: 1360px) {
    .collegiate-spouse-container {
        max-width: 70%;
    }
}

@media screen and (max-width: 1200px) {
    .collegiate-spouse-container {
        max-width: 80%;
    }
}

@media screen and (max-width: 1100px) {
    .collegiate-spouse-container {
        max-width: 90%;
    }
}

@media screen and (max-width: 850px) {
    .collegiate-spouse-container {
        max-width: 100%;
    }
}

/* Paso 3 */

.spouse-container {
    max-width: 60%;
}

.main-inputs-container-spouse {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.inputs-container-spouse {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

@media screen and (max-width: 1626px) {
    .spouse-container {
        max-width: 70%;
    }
}

@media screen and (max-width: 1430px) {
    .spouse-container {
        max-width: 80%;
    }
}

@media screen and (max-width: 1300px) {
    .spouse-container {
        max-width: 90%;
    }
}

@media screen and (max-width: 1150px) {
    .spouse-container {
        max-width: 100%;
    }
}

@media screen and (max-width: 1030px) {
    .main-inputs-container-spouse {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .input-item {
        margin-bottom: 1rem;
    }

    .null-item {
        display: none;
    }
}

/* Paso 4 o 3 depende */

.container-beneficiary {
    max-width: 70%;
}

.beneficiary-general-container {
    display: grid;
    grid-template-columns: 3fr 1fr;
    grid-gap: 1rem;
}

.beneficiary-general-info {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));

}

.beneficiary-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

@media screen and (max-width: 1170px) {
    .beneficiary-general-container {
        min-width: 915px;
    }
}

@media screen and (max-width: 1700px) {
    .container-beneficiary {
        max-width: 75%;
    }
}

@media screen and (max-width: 1600px) {
    .container-beneficiary {
        max-width: 80%;
    }
}

@media screen and (max-width: 1350px) {
    .container-beneficiary {
        max-width: 90%;
    }
}

@media screen and (max-width: 1225px) {
    .container-beneficiary {
        max-width: 100%;
    }
}

.general-inputs-beneficiary-container {
    margin-top: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.inputs-container-beneficiary {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

@media screen and (max-width: 1050px) {
    .general-inputs-beneficiary-container {
        margin-top: 1rem;
        display: flex;
        flex-direction: column;
    }
    
    .null-beneficiary-item {
        display: none;
    }

    .beneficiary-input-item {
        margin-bottom: 1rem;
    }
}