.forgot-password-form {
    margin: 0 !important;
    padding: 0 !important;
}

.forgot-password-form::before {
    content: "";
    background-image: url('../../../../../assets/img/background.png');
    background-size: cover;
    background-color: transparent;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    z-index: 10;
    margin-left: -7px;
    border-radius: 10px 0 0 10px;
}

.forgot-password-formulate {
    max-width: 380px;
}

.email-field {
    color: black !important;
}

.email-field.icon {
    background-image: url('../../../../../assets/img/icons/emailicon.png');
    background-repeat: no-repeat;
    background-position: 10px 10px;
}

.forgot-password-button {
    background: #0A4725 !important;
    color: white !important;
    border-radius: 1rem !important;
    width: 90% !important;
}