.no-padding .btn, .no-padding .btn:hover {
  padding: 0 !important;
  background-color: transparent !important;
  box-shadow: none !important;
}

.container-tree .category-option:nth-child(even) {
  background-color: #B0B0B0;
  border-radius: 10px;
}

.category-option {
  display: flex;
  padding-bottom: 3px;
}

.category-option:hover {
  background-color: #FAFDF3 !important;
  border-radius: 10px !important;
}

.bg-tree {
  padding: 0.5rem 1.3rem 0.3rem 0;
  background-color: rgba(0, 0, 0, .05);
}

.text-icon-align-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.container-more-event {
  position: absolute !important;
  right: 35px;
  width: 5px;
  line-height: 10px;
}
