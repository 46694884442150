* {
    font-size: 16px;
}

.new-password-container {
    background-image: url(../../../../../assets//img/background.png);
    background-size: cover;
}

@media screen and (min-width: 1920px) {
    .change-password-card {
        max-width: 60%;
    }
}

@media screen and (max-width: 1919px) {
    .change-password-card {
        max-width: 70%;
    }
}

@media screen and (max-width: 1590px) {
    .change-password-card {
        max-width: 75%;
    }
}

@media screen and (max-width: 1400px) {
    .change-password-card {
        max-width: 80%;
    }
}

@media screen and (max-width: 1250px) {
    .change-password-card {
        max-width: 90%;
    }
}

@media screen and (max-width: 767px) {
    .change-password-card {
        max-width: 95%;
    }
}

.new-password-button {
    background: #0A4725 !important;
    color: white !important;
    border-radius: 1rem !important;
}

.new-password-tips-card-icons {
    height: 12px;
    width: 12px;
    margin-right: 5px !important;
}

.change-password-form .password-field.icon {
    background-image: url(../../../../../assets/img/icons/lockicon.png);
    background-repeat: no-repeat;
    background-position: 10px 10px;
}

.icon-yes {
    filter: invert(41%) sepia(97%) saturate(401%) hue-rotate(90deg) brightness(89%) contrast(88%);
}

.icon-no {
    filter: invert(12%) sepia(95%) saturate(6750%) hue-rotate(17deg) brightness(97%) contrast(109%);
}

.change-password-form .form-control.is-invalid {
    border: 1.5px solid #B0A196 !important;
}