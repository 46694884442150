.key-detail {
  font-weight: 600;
  font-size: 14px;
  color: #4B4D58;
}

.value-detail {
  color: #5A6169;
  font-size: 14px;
}

.color-type-number {
  color: #0F3B0F;
}

.color-type-boolean {
  color: red;
}

.color-type-string {
  color: blueviolet;
}

.color-type-obj {
  color: #0A0C0D;
}

.color-type-undefined {
  color: #0A0C0D;
}

.line-height-13 {
  line-height: 13px;
}

.text-remark {
  color: orangered;
}

.border-details {
  padding: 20px 0 15px 25px;
  border: #B0A196 solid 1px;
  border-radius: 15px;
}

.mh-div-details {
  max-height: 550px;
  overflow-y: auto;
  overflow-x: hidden;
}

.mh-div-details::-webkit-scrollbar {
  width: 1px;
}
