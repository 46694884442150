.option-action {
  background-color: transparent !important;
  border: none !important;
  min-width: 50px;
  margin-left: 10px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.option:hover {
  padding-top: 2px;
  background-color: #E2DDD9 !important;
  border: none !important;
  border-radius: 20px;
}

.tree {
  max-width: 88vw;
}

.tree .active {
  margin-top: 3px;
  background-color: #E2DDD9 !important;
  /* width: 100%; */
  /* border: 1px solid #B0A196 !important; */
  border-radius: 20px;
}

.tree::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.tree::-webkit-scrollbar-track {
  border-radius: 10px;
  padding-top: 40px;
  padding-bottom: 40px;
}

.tree::-webkit-scrollbar-thumb {
  background: #625d5d;
  border-radius: 10px;
}

.tree .option {
  display: flex;
  width: fit-content;
  position: relative;
  align-items: center;
}

.with-error {
  border: 1px solid #FF0000 !important;
}

.text-select-cat {
  color: #4B4D58;
  font-size: 1rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.text-select-error {
  color: #dc3545;
  font-size: 12px;
  position: absolute;
}

@media only screen and (max-width: 768px) {
  .tree {
    margin-left: -60px;
    max-width: 95vw;
    min-width: 95vw;
  }
  .tree button {
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0;
  }
}
