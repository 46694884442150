.customOverlay {
    background: rgba(36, 123, 160, 0.7);
}

.custom-modal {
    padding: 45px;
    background: #FAFDF3;
    border: 1px solid #B0A196;
    border-radius: 8px;
    /* min-width: 60%;
    min-height: 60%; */
}