.background-img {
  background-image: url('../../../../assets/img/background.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 100vh;
}

.qr-main-content {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

.other-content {
  width: 85% !important;
}

.qr-border {
  border-radius: 10px;
}

.border-card-qr {
  border-radius: 10px;
}

.qr-first-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.row-qr-container {
  margin-top: 1rem;
}

@media screen and (max-width: 1170px) {
  .qr-first-item {
    flex-direction: column;
  }
}

.row-qr-data {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  min-height: 104px;
  grid-gap: 1rem;
}

.row-qr-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.qr-text {
  text-align: center;  
}