.separator {
    position: relative;
    margin-right: 20px;
}

.separator::after {
    content: "";
    display: block;
    width: 2.5px;
    height: 80%;
    background-color: #b0a196;
    position: absolute;
    top: calc(50% - 40%);
    right: -18px;
}

@media only screen and (max-width: 1200px) {
    .separator {
        display: none;
    }
    .field-min-width {
        min-width: 200px !important;
    }
}