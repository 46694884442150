.login-form {
    margin: 0 !important;
    padding: 0 !important;
}

.login-form::before {
    content: "";
    background-image: url('../../../../../assets/img/background.png');
    background-size: cover;
    background-color: transparent;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    margin-right: -7px;
    border-radius: 0 10px 10px 0;
}

.login-formulate .form-control {
    border-color: #B0A196;
    border-radius: 20px !important;
}

.login-formulate .dpi-field.icon {
    background-image: url('../../../../../assets/img/icons/personicon.png') !important;
    background-repeat: no-repeat;
    background-position: 9px 11px !important;
}

.login-formulate .password-field.icon {
    background-image: url('../../../../../assets/img/icons/lockicon.png') !important;
    background-repeat: no-repeat;
    background-position: 9px 11px !important;
}

.dpi-field::placeholder {
    color: #B0A196 !important;
}

.password-field::placeholder {
    color: #B0A196 !important;
}

.dpi-field {
    color: black !important;
}

.password-field {
    color: black !important;
}

.input-password {
    display: flex;
    flex-direction: column;
}

.hide-eye-icon {
    display: none;
}

.input-password .icon-eye{
    position: absolute;
    align-self: flex-end;
    padding-top: 40px;
    padding-right: 10px;
}

.input-password .icon-eye img{
    cursor: pointer;
}

.login-button {
    background: #0A4725 !important;
    color: white !important;
    border-radius: 1rem !important;
    width: 90% !important;
}
