/* .general-card {
    display: grid;
    grid-template-columns: 1fr 2fr 2fr 2fr 2fr;
    grid-gap: 20px;
    grid-template-rows: 12fr;
}

.number {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
} */

/* .required-document {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
} */

.required-document .c-checkbox input[type='checkbox'] + span{
    border: 2px solid #777FF3 !important;
    border-radius: 4px;
}

.required-document .c-checkbox input[type='checkbox']:checked + span{
    background-color: #777FF3 !important;
    box-shadow: inset 1px 1px #FAFDF3 , inset -1px -1px #FAFDF3 ;
}

.btn-delete-document:hover {
    background-color: #E60F0F;
  }
.btn-delete-document:hover img{
    content: url('../../../../../../../assets/img/icons/dump.svg');
}

.btn-upload-document:hover {
    background-color: #777FF3;
  }
.btn-upload-document:hover img{
    content: url('../../../../../../../assets/img/icons/uploadWhite.svg');
}

@media screen and (max-width: 1400px) {
    .required-document {
        /* display: flex !important; */
        flex-direction: column-reverse;
        align-items: flex-start;
        /* align-items: center !important; */

    }
}

@media screen and (min-width: 1400px) {
    .required-document {
        /* display: flex !important; */
        /* flex-direction: column-reverse; */
        /* display: flex;
        flex-direction: row; */
        align-items: center;
        justify-content: center;
        /* align-items: center !important; */

    }
}
