.cash-container .rc-tabs-top {
  border: none;
}

.cash-container .rc-tabs-nav-wrap {
  background: transparent;
}

.cash-container .rc-tabs-tab {
  background: #FAFDF3 !important;
  font-family: 'Roboto';
  font-weight: 600 !important;
  font-size: 20px !important;
  line-height: 23px !important;
  text-align: center !important;
  color: #4B4D58 !important;
  border-radius: 20px 20px 0 0 !important;
  box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.25) !important;
}

.cash-container .rc-tabs-tab-btn {
  font-size: 21px !important;
}

.cash-container .rc-tabs-tab.rc-tabs-tab-active {
  color: #4B4D58 !important;
  background: var(--bg-card) !important;
  z-index: 1;
  margin-bottom: -2px;
}

.cash-container .rc-tabs-ink-bar {
  background: #E2DDD9;
}

.item-cancelled {
  line-height: 1.9rem;
  text-decoration: 1px line-through var(--input-color);
}
