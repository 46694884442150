.jodit-toolbar__box {
  border-radius: 20px 20px 0px 0px !important;
  background: #FAFDF3 !important;
  border: 2px solid #B0A196;
}

.jodit-container:not(.jodit_inline) .jodit-wysiwyg::-webkit-scrollbar {
  width: 3px;
}

.jodit-container:not(.jodit_inline) .jodit-wysiwyg::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 25px;
}

.jodit-container:not(.jodit_inline) .jodit-wysiwyg::-webkit-scrollbar-thumb {
  background: #B0A196;
  border-radius: 25px;
  margin-right: 2px;
}
