/* @media only screen and (min-width: 390px) and (max-width: 844px) {
    .permissions-roles-form {
        min-width: 100% !important;
    }
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
    .permissions-roles-form {
        min-width: 100%;
    }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
    .permissions-roles-form {
        min-width: 100%;
    }
} */

/* @media only screen and (min-width: 768px) and (max-width: 979px) {
    .permissions-roles-form {
        min-width: 100%;
    }
} */

@media only screen and (min-width: 700px) and (max-width: 1023px) {
    .permissions-roles-form {
        min-width: 100%;
    }
}

@media only screen and (min-width: 1024px) and (max-width: 1299px) {
    .permissions-roles-form {
        max-width: 60%;
    }
}
@media only screen and (min-width: 1300px) and (max-width: 1349px) {
    .permissions-roles-form {
        max-width: 70%;
    }
    .permissions-list {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
}


@media only screen and (min-width: 1355px) and (max-width: 1599px) {
    .permissions-roles-form {
        max-width: 65%;
    }
    .permissions-list {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
}

@media only screen and (min-width: 1600px){
    .permissions-roles-form {
        max-width: 55%;
    }
    .permissions-list {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
}

@media only screen and (min-width: 1700px){
    .permissions-roles-form {
        max-width: 48%;
    }
    .permissions-list {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
}

.permissions-roles-form {
    background-color: #E2DDD9;
    border-radius: 20px;
    box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.25);
}

.name-field {
    background-color: #FAFDF3 !important;
    border: 1.5px solid #B0A196 !important;
    border-radius: 20px !important;
    min-width: 20%;
    color: black !important;
}

.name-field::placeholder {
    color: #B0A196 !important;
    font-size: 16px;
    font-weight: 400 !important;
}

.modules-reports-container {
    background-color: #FAFDF3 !important;
    border: 1.5px solid #B0A196 !important;
    border-radius: 20px !important;
    color: #4B4D58;
    box-shadow: none !important;
    height: 90%;
    min-width: max-content !important;
}

.cards-checkbox .c-checkbox input[type='checkbox'] + span{
    border: 2px solid #777FF3 !important;
    border-radius: 4px;
}

.cards-checkbox .c-checkbox input[type='checkbox']:checked + span{
    background-color: #777FF3 !important;
    box-shadow: inset 1px 1px #FAFDF3 , inset -1px -1px #FAFDF3 ;
}

.form-buttons {
    color: #FAFDF3 !important;
    font-weight: bold !important;
    font-size: 18px !important;
    width: 200px;
    border-radius: 20px !important;
}

.reset-button {
    background-color: #B0B0B0 !important;
    min-width: 28% !important;
}

.icon-reset {
    background-image: url(../../../../../../assets/img/icons/cancelicon.png);
    background-repeat: no-repeat;
}

.save-button {
    background-color: #157339 !important;
    min-width: 28% !important;
}

.icon-save {
    background-image: url(../../../../../../assets/img/icons/saveicon.png);
    background-repeat: no-repeat;
    background-position: 0 -1px;
}

.title-color {
    font-size: 16px !important;
    color:  black !important;
}
