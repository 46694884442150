.parent {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: repeat(5, 1fr);
  grid-column-gap: 16px;
  grid-row-gap: 0px;
}

.parent .date-filter {
  text-overflow: ellipsis;
  white-space: nowrap;
}

.parent div:nth-child(1) {
  grid-area: 1 / 1 / 6 / 5;
}
.parent div:nth-child(2) {
  grid-area: 1 / 5 / 6 / 13;
}

/* mobile  view */

@media (max-width: 1200px) {
  .parent {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: repeat(6, minmax(1fr, 200px));
    grid-column-gap: 16px;
    grid-row-gap: 0px;
  }

  .parent div:nth-child(1) {
    grid-area: 6/ 1 / 7 / 13;
  }
  .parent div:nth-child(2) {
    grid-area: 1 / 1 / 6/ 13;
  }
  .parent * {
    transform: scale(0.98);
  }
}
