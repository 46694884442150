.my-managements-buttons-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(264px, 1fr));
    /* grid-gap: 30px; */
    margin-top: 20px;
    padding: 3vw;
    border-radius: 20px;
}

.my-managements-button {
    width: 264px;
    height: 233px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.my-managements-button:hover {
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15);
}

@media screen and (max-width: 660px) {
    .my-managements-buttons-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}